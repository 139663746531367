"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TestMetricsV3SlSchema = exports.TestMetricsFilters = void 0;
var sl_schema_1 = require("../../sl-schema");
var TestMetricsFilters;
(function (TestMetricsFilters) {
  TestMetricsFilters["ExecutedFromTiaRecommendations"] = "executedFromTiaRecommendations";
  TestMetricsFilters["ExecutedRecommendationsNotReadyOnTime"] = "executedRecommendationsNotReadyOnTime";
  TestMetricsFilters["ExecutedButRecommendedToSkip"] = "executedButRecommendedToSkip";
  TestMetricsFilters["SkippedFromRecommendations"] = "skippedFromRecommendations";
  TestMetricsFilters["SkippedByUser"] = "skippedByUser";
  TestMetricsFilters["SkippedButFullRunRecommended"] = "skippedButFullRunRecommended";
  TestMetricsFilters["ExecutedFromFullRunPolicy"] = "executedFromFullRunPolicy";
  TestMetricsFilters["Error"] = "error";
})(TestMetricsFilters = exports.TestMetricsFilters || (exports.TestMetricsFilters = {}));
var TestMetricsV3SlSchema = /** @class */function (_super) {
  __extends(TestMetricsV3SlSchema, _super);
  function TestMetricsV3SlSchema() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  TestMetricsV3SlSchema.prototype.route = function () {
    return '/api/v3/tia/build/test-metrics/query';
  };
  TestMetricsV3SlSchema.prototype.sortable = function () {
    return ['testName', 'testExecutionStatus', 'testResult', 'testGroupId'];
  };
  TestMetricsV3SlSchema.prototype.filterable = function () {
    return ['testResult', 'testExecutionStatus', 'testName', 'recommendationReasons'];
  };
  TestMetricsV3SlSchema.prototype.countable = function () {
    return ['testResult', 'testExecutionStatus', 'recommendationReasons'];
  };
  TestMetricsV3SlSchema.prototype.required = function () {
    return [];
  };
  TestMetricsV3SlSchema.prototype.expectStatus = function () {
    return true;
  };
  Object.defineProperty(TestMetricsV3SlSchema, "schema", {
    get: function () {
      return new TestMetricsV3SlSchema();
    },
    enumerable: false,
    configurable: true
  });
  return TestMetricsV3SlSchema;
}(sl_schema_1.SlBaseSchema);
exports.TestMetricsV3SlSchema = TestMetricsV3SlSchema;
