"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TiaMode = exports.TiaTimelineExternalizedEventType = void 0;
var TiaTimelineExternalizedEventType;
(function (TiaTimelineExternalizedEventType) {
  TiaTimelineExternalizedEventType["BUILD"] = "BUILD";
  TiaTimelineExternalizedEventType["TSR"] = "TSR";
  TiaTimelineExternalizedEventType["TOGGLES"] = "TOGGLES";
  TiaTimelineExternalizedEventType["POLICY_UPDATE"] = "POLICY_UPDATE";
})(TiaTimelineExternalizedEventType = exports.TiaTimelineExternalizedEventType || (exports.TiaTimelineExternalizedEventType = {}));
var TiaMode;
(function (TiaMode) {
  TiaMode["BUILD"] = "BUILD";
  TiaMode["TSR"] = "TSR";
})(TiaMode = exports.TiaMode || (exports.TiaMode = {}));
