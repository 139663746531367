"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SortDir = void 0;
var SortDir;
(function (SortDir) {
  SortDir[SortDir["ASC"] = 1] = "ASC";
  SortDir[SortDir["DESC"] = -1] = "DESC";
})(SortDir = exports.SortDir || (exports.SortDir = {}));
