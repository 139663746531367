"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimelineMessageType = void 0;
var TimelineMessageType;
(function (TimelineMessageType) {
  TimelineMessageType["INFO"] = "INFO";
  TimelineMessageType["WARN"] = "WARN";
  TimelineMessageType["ERROR"] = "ERROR";
})(TimelineMessageType = exports.TimelineMessageType || (exports.TimelineMessageType = {}));
