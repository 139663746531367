"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SlTotalSchema = exports.SlBaseSchema = void 0;
var SlBaseSchema = /** @class */function () {
  function SlBaseSchema() {}
  SlBaseSchema.prototype.totalsRoute = function () {
    return this.route() + "/totals";
  };
  SlBaseSchema.prototype.externalTotalSchemas = function () {
    return {};
  };
  ;
  Object.defineProperty(SlBaseSchema, "schema", {
    get: function () {
      throw new Error('Unimplemeted');
    },
    enumerable: false,
    configurable: true
  });
  return SlBaseSchema;
}();
exports.SlBaseSchema = SlBaseSchema;
var SlTotalSchema = /** @class */function () {
  function SlTotalSchema() {}
  Object.defineProperty(SlTotalSchema, "schema", {
    get: function () {
      throw new Error('Unimplemeted');
    },
    enumerable: false,
    configurable: true
  });
  return SlTotalSchema;
}();
exports.SlTotalSchema = SlTotalSchema;
