"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApplicationUnderTestV1Schema = void 0;
var sl_schema_1 = require("../../../sl-schema");
var ApplicationUnderTestV1Schema = /** @class */function (_super) {
  __extends(ApplicationUnderTestV1Schema, _super);
  function ApplicationUnderTestV1Schema() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  ApplicationUnderTestV1Schema.prototype.route = function () {
    return '/api/v1/tia/aut/resolve';
  };
  ApplicationUnderTestV1Schema.prototype.sortable = function () {
    return ['appName', 'branchName', 'buildName', 'status'];
  };
  ApplicationUnderTestV1Schema.prototype.filterable = function () {
    return ['appName', 'branchName', 'status'];
  };
  ApplicationUnderTestV1Schema.prototype.countable = function () {
    return ['appName', 'branchName', 'status'];
  };
  ApplicationUnderTestV1Schema.prototype.required = function () {
    return [];
  };
  ApplicationUnderTestV1Schema.prototype.expectStatus = function () {
    return false;
  };
  Object.defineProperty(ApplicationUnderTestV1Schema, "schema", {
    get: function () {
      return new ApplicationUnderTestV1Schema();
    },
    enumerable: false,
    configurable: true
  });
  return ApplicationUnderTestV1Schema;
}(sl_schema_1.SlBaseSchema);
exports.ApplicationUnderTestV1Schema = ApplicationUnderTestV1Schema;
