"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BuildTestStagesV1SlSchema = void 0;
var sl_schema_1 = require("../../../sl-schema");
var BuildTestStagesV1SlSchema = /** @class */function (_super) {
  __extends(BuildTestStagesV1SlSchema, _super);
  function BuildTestStagesV1SlSchema() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  BuildTestStagesV1SlSchema.prototype.route = function () {
    return '/api/v1/test-stages/resolve';
  };
  BuildTestStagesV1SlSchema.prototype.sortable = function () {
    return ["testStage", "testsDuration", "status", "tiaStatus", "coverageCalculationStatus", "lastCoverageCalculationStatusTime", "tsrCount", "testCount", "failedCount", "passedCount", "skippedCount", "regressionCount", "cancelledCount", "overallCoverage", "modifiedCoverage", "untestedCoverage", "qrs"];
  };
  BuildTestStagesV1SlSchema.prototype.filterable = function () {
    return ["testStage", "status", "tiaStatus", "coverageCalculationStatus"];
  };
  BuildTestStagesV1SlSchema.prototype.countable = function () {
    return ["testStage", "status", "tiaStatus"];
  };
  BuildTestStagesV1SlSchema.prototype.required = function () {
    return ["buildSessionId"];
  };
  BuildTestStagesV1SlSchema.prototype.expectStatus = function () {
    return false;
  };
  Object.defineProperty(BuildTestStagesV1SlSchema, "schema", {
    get: function () {
      return new BuildTestStagesV1SlSchema();
    },
    enumerable: false,
    configurable: true
  });
  return BuildTestStagesV1SlSchema;
}(sl_schema_1.SlBaseSchema);
exports.BuildTestStagesV1SlSchema = BuildTestStagesV1SlSchema;
