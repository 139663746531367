"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TimelineToggleMessage = void 0;
var TimelineToggleMessage;
(function (TimelineToggleMessage) {
  TimelineToggleMessage["TIA_ON"] = "TIA_ON";
  TimelineToggleMessage["TIA_OFF"] = "TIA_OFF";
  TimelineToggleMessage["TOGGLE_NEXT_RUN_AS_FULL_RUN_ON"] = "TOGGLE_NEXT_RUN_AS_FULL_RUN_ON";
  TimelineToggleMessage["TOGGLE_NEXT_RUN_AS_FULL_RUN_OFF"] = "TOGGLE_NEXT_RUN_AS_FULL_RUN_OFF";
  TimelineToggleMessage["RECOMMENDATION_MODE_BUILD"] = "RECOMMENDATION_MODE_BUILD";
  TimelineToggleMessage["RECOMMENDATION_MODE_TSR"] = "RECOMMENDATION_MODE_TSR";
})(TimelineToggleMessage = exports.TimelineToggleMessage || (exports.TimelineToggleMessage = {}));
