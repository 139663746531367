"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TestMetadataV3SlSchema = exports.TestMetadataV1SlSchema = void 0;
var sl_schema_1 = require("../../sl-schema");
var test_management_1 = require("../test-management");
var TestMetadataV1SlSchema = /** @class */function (_super) {
  __extends(TestMetadataV1SlSchema, _super);
  function TestMetadataV1SlSchema() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  TestMetadataV1SlSchema.prototype.route = function () {
    return '/api/v1/tia/test-metadata/resolve';
  };
  TestMetadataV1SlSchema.prototype.sortable = function () {
    return ["branchName", "testGroupId", "appName", "testStage", "name", "isPinned"];
  };
  TestMetadataV1SlSchema.prototype.filterable = function () {
    return ["branchName", "testGroupId", "appName", "testStage", "name", "isPinned"];
  };
  TestMetadataV1SlSchema.prototype.countable = function () {
    return ["branchName", "testGroupId", "appName", "testStage", "isPinned"];
  };
  TestMetadataV1SlSchema.prototype.required = function () {
    return [];
  };
  TestMetadataV1SlSchema.prototype.expectStatus = function () {
    return false;
  };
  Object.defineProperty(TestMetadataV1SlSchema, "schema", {
    get: function () {
      return new TestMetadataV1SlSchema();
    },
    enumerable: false,
    configurable: true
  });
  return TestMetadataV1SlSchema;
}(sl_schema_1.SlBaseSchema);
exports.TestMetadataV1SlSchema = TestMetadataV1SlSchema;
var TestMetadataV3SlSchema = /** @class */function (_super) {
  __extends(TestMetadataV3SlSchema, _super);
  function TestMetadataV3SlSchema() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  TestMetadataV3SlSchema.prototype.route = function () {
    return '/api/v1/tia/test-metadata/resolve';
  };
  TestMetadataV3SlSchema.prototype.externalTotalSchemas = function () {
    return {
      appName: test_management_1.TiaAppsV1Schema.schema,
      branchName: test_management_1.TiaBranchesV1Schema.schema,
      testStage: test_management_1.TiaTestStagesV1Schema.schema,
      testGroupId: test_management_1.TiaTestGroupsV1Schema.schema
    };
  };
  TestMetadataV3SlSchema.prototype.sortable = function () {
    return ["branchName", "testGroupId", "appName", "testStage", "name", "isPinned"];
  };
  TestMetadataV3SlSchema.prototype.filterable = function () {
    return ["branchName", "testGroupId", "appName", "testStage", "name", "isPinned"];
  };
  TestMetadataV3SlSchema.prototype.countable = function () {
    return ["branchName", "testGroupId", "appName", "testStage"];
  };
  TestMetadataV3SlSchema.prototype.required = function () {
    return [];
  };
  TestMetadataV3SlSchema.prototype.expectStatus = function () {
    return false;
  };
  Object.defineProperty(TestMetadataV3SlSchema, "schema", {
    get: function () {
      return new TestMetadataV3SlSchema();
    },
    enumerable: false,
    configurable: true
  });
  return TestMetadataV3SlSchema;
}(sl_schema_1.SlBaseSchema);
exports.TestMetadataV3SlSchema = TestMetadataV3SlSchema;
