"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RecommendationMode = exports.ReferenceBuildConfiguration = exports.PolicyType = exports.NoCodeChangePolicy = void 0;
var NoCodeChangePolicy;
(function (NoCodeChangePolicy) {
  NoCodeChangePolicy["SkipAllTests"] = "skipAllTests";
  NoCodeChangePolicy["RunAllTests"] = "runAllTests";
})(NoCodeChangePolicy = exports.NoCodeChangePolicy || (exports.NoCodeChangePolicy = {}));
var PolicyType;
(function (PolicyType) {
  PolicyType["Daily"] = "daily";
  PolicyType["Weekly"] = "weekly";
  PolicyType["Monthly"] = "monthly";
  PolicyType["EveryXTestRuns"] = "everyXTestRuns";
  PolicyType["EveryXMonths"] = "everyXMonth";
})(PolicyType = exports.PolicyType || (exports.PolicyType = {}));
var ReferenceBuildConfiguration;
(function (ReferenceBuildConfiguration) {
  ReferenceBuildConfiguration["PreviousBuild"] = "previousBuild";
  ReferenceBuildConfiguration["ReferenceBuild"] = "referenceBuild";
})(ReferenceBuildConfiguration = exports.ReferenceBuildConfiguration || (exports.ReferenceBuildConfiguration = {}));
var RecommendationMode;
(function (RecommendationMode) {
  RecommendationMode["Build"] = "build";
  RecommendationMode["Tsr"] = "tsr";
})(RecommendationMode = exports.RecommendationMode || (exports.RecommendationMode = {}));
