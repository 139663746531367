"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BranchesFilterV5SlSchema = void 0;
var sl_schema_1 = require("../../sl-schema");
var BranchesFilterV5SlSchema = /** @class */function (_super) {
  __extends(BranchesFilterV5SlSchema, _super);
  function BranchesFilterV5SlSchema() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  BranchesFilterV5SlSchema.prototype.route = function () {
    return '/api/v5/tia/apps/test-stages/branches/query';
  };
  BranchesFilterV5SlSchema.prototype.sortable = function () {
    return [];
  };
  BranchesFilterV5SlSchema.prototype.filterable = function () {
    return ["branchName"];
  };
  BranchesFilterV5SlSchema.prototype.countable = function () {
    return [];
  };
  BranchesFilterV5SlSchema.prototype.required = function () {
    return ['appName', "testStage"];
  };
  BranchesFilterV5SlSchema.prototype.expectStatus = function () {
    return false;
  };
  Object.defineProperty(BranchesFilterV5SlSchema, "schema", {
    get: function () {
      return new BranchesFilterV5SlSchema();
    },
    enumerable: false,
    configurable: true
  });
  return BranchesFilterV5SlSchema;
}(sl_schema_1.SlBaseSchema);
exports.BranchesFilterV5SlSchema = BranchesFilterV5SlSchema;
