"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TsrMetricsV1SlSchema = void 0;
var sl_schema_1 = require("../../../sl-schema");
var TsrMetricsV1SlSchema = /** @class */function (_super) {
  __extends(TsrMetricsV1SlSchema, _super);
  function TsrMetricsV1SlSchema() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  TsrMetricsV1SlSchema.prototype.route = function () {
    return '/api/v1/tsr-history/stages/resolve';
  };
  TsrMetricsV1SlSchema.prototype.sortable = function () {
    return ["tsrId", "testsDuration", "status", "tiaStatus", "testCount", "failedCount", "passedCount", "skippedCount", "regressionCount", "cancelledCount"];
  };
  TsrMetricsV1SlSchema.prototype.filterable = function () {
    return ["tsrId", "status", "tiaStatus"];
  };
  TsrMetricsV1SlSchema.prototype.countable = function () {
    return ["tsrId", "status", "tiaStatus"];
  };
  TsrMetricsV1SlSchema.prototype.required = function () {
    return ["buildSessionId", "testStage"];
  };
  TsrMetricsV1SlSchema.prototype.expectStatus = function () {
    return false;
  };
  Object.defineProperty(TsrMetricsV1SlSchema, "schema", {
    get: function () {
      return new TsrMetricsV1SlSchema();
    },
    enumerable: false,
    configurable: true
  });
  return TsrMetricsV1SlSchema;
}(sl_schema_1.SlBaseSchema);
exports.TsrMetricsV1SlSchema = TsrMetricsV1SlSchema;
