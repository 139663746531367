"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PolicyUpdateTimelineMessageKey = void 0;
var PolicyUpdateTimelineMessageKey;
(function (PolicyUpdateTimelineMessageKey) {
  PolicyUpdateTimelineMessageKey["CI_DAILY"] = "CI_DAILY";
  PolicyUpdateTimelineMessageKey["MANUAL_DAILY"] = "MANUAL_DAILY";
  PolicyUpdateTimelineMessageKey["CI_WEEKLY"] = "CI_WEEKLY";
  PolicyUpdateTimelineMessageKey["MANUAL_WEEKLY"] = "MANUAL_WEEKLY";
  PolicyUpdateTimelineMessageKey["CI_MONTHLY"] = "CI_MONTHLY";
  PolicyUpdateTimelineMessageKey["MANUAL_MONTHLY"] = "MANUAL_MONTHLY";
  PolicyUpdateTimelineMessageKey["CI_EVERY_X_TEST_RUNS"] = "CI_EVERY_X_TEST_RUNS";
  PolicyUpdateTimelineMessageKey["MANUAL_EVERY_X_TEST_RUNS"] = "MANUAL_EVERY_X_TEST_RUNS";
  PolicyUpdateTimelineMessageKey["CI_EVERY_X_MONTH"] = "CI_EVERY_X_MONTH";
  PolicyUpdateTimelineMessageKey["MANUAL_EVERY_X_MONTH"] = "MANUAL_EVERY_X_MONTH";
})(PolicyUpdateTimelineMessageKey = exports.PolicyUpdateTimelineMessageKey || (exports.PolicyUpdateTimelineMessageKey = {}));
