"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FullRunReason = exports.TestRecommendationSetStatus = void 0;
var TestRecommendationSetStatus;
(function (TestRecommendationSetStatus) {
  TestRecommendationSetStatus["NotReady"] = "notReady";
  TestRecommendationSetStatus["NoHistory"] = "noHistory";
  TestRecommendationSetStatus["Ready"] = "ready";
  TestRecommendationSetStatus["Error"] = "error";
})(TestRecommendationSetStatus = exports.TestRecommendationSetStatus || (exports.TestRecommendationSetStatus = {}));
var FullRunReason;
(function (FullRunReason) {
  FullRunReason["Unsupported"] = "unsupported";
  FullRunReason["TestSelectionOff"] = "testSelectionOff";
  FullRunReason["ScheduledFullRun"] = "scheduledFullRun";
  FullRunReason["RecommendationsError"] = "recommendationsError";
  FullRunReason["RecommendationsNotAvailable"] = "recommendationsNotAvailable";
  FullRunReason["TestSelectionDisabledByAgent"] = "testSelectionDisabledByAgent";
  FullRunReason["UserScheduledFullRun"] = "userScheduledFullRun";
  // virtual reasons derived from data
  FullRunReason["NoSkippedTestsByTia"] = "noSkippedTestsByTia";
  FullRunReason["NoHistory"] = "noHistory";
})(FullRunReason = exports.FullRunReason || (exports.FullRunReason = {}));
