"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TiaBranchesV1Schema = void 0;
var sl_schema_1 = require("../../../sl-schema");
var TiaBranchesV1Schema = /** @class */function (_super) {
  __extends(TiaBranchesV1Schema, _super);
  function TiaBranchesV1Schema() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  TiaBranchesV1Schema.prototype.totalsRoute = function () {
    return '/api/v1/tia/test-management/branches/totals/resolve';
  };
  TiaBranchesV1Schema.prototype.filterable = function () {
    return ['appName', 'branchName'];
  };
  TiaBranchesV1Schema.prototype.required = function () {
    return [];
  };
  Object.defineProperty(TiaBranchesV1Schema, "schema", {
    get: function () {
      return new TiaBranchesV1Schema();
    },
    enumerable: false,
    configurable: true
  });
  return TiaBranchesV1Schema;
}(sl_schema_1.SlTotalSchema);
exports.TiaBranchesV1Schema = TiaBranchesV1Schema;
