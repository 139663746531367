"use strict";

var __extends = this && this.__extends || function () {
  var extendStatics = function (d, b) {
    extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p];
    };
    return extendStatics(d, b);
  };
  return function (d, b) {
    if (typeof b !== "function" && b !== null) throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
    extendStatics(d, b);
    function __() {
      this.constructor = d;
    }
    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TiaSettingsV3SlSchema = void 0;
var sl_schema_1 = require("../../sl-schema");
var TiaSettingsV3SlSchema = /** @class */function (_super) {
  __extends(TiaSettingsV3SlSchema, _super);
  function TiaSettingsV3SlSchema() {
    return _super !== null && _super.apply(this, arguments) || this;
  }
  TiaSettingsV3SlSchema.prototype.route = function () {
    return '/api/v3/tia/settings/resolve';
  };
  TiaSettingsV3SlSchema.prototype.sortable = function () {
    return ['branchName', 'tiaOn', 'tiaOnForPr', 'recommendationMode', 'closeExecutionTimeoutSec', 'noCodeChangePolicy', 'calculateChangesFrom', 'activateNextFullRun', 'fullRunPolicy'];
  };
  TiaSettingsV3SlSchema.prototype.filterable = function () {
    return ['branchName']; // only with Operator.Equals
  };
  TiaSettingsV3SlSchema.prototype.countable = function () {
    return ['branchName'];
  };
  TiaSettingsV3SlSchema.prototype.required = function () {
    return ['appName', 'testStage'];
  };
  TiaSettingsV3SlSchema.prototype.expectStatus = function () {
    return false;
  };
  Object.defineProperty(TiaSettingsV3SlSchema, "schema", {
    get: function () {
      return new TiaSettingsV3SlSchema();
    },
    enumerable: false,
    configurable: true
  });
  return TiaSettingsV3SlSchema;
}(sl_schema_1.SlBaseSchema);
exports.TiaSettingsV3SlSchema = TiaSettingsV3SlSchema;
